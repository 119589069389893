
import { defineComponent } from "vue";
import { useResize, Data as UseResizeData } from "@/toolkit/mixins";
import Navigation from "@/components/Navigation.vue";

interface Data extends UseResizeData {
  showSmall: boolean;
  isHome: boolean;
  email: string;
  linkedin: string;
  github: string;
  location: string;
  phone: string;
}

export default defineComponent({
  name: "Resume",
  components: {
    Navigation
  },
  mixins: [useResize()],
  computed: {
    isHome(): boolean {
      return this.$route.path === "/resume";
    }
  },
  data(): Partial<Data> {
    return {
      showSmall: false,
      windowWidth: 0,
      email: process.env.VUE_APP_EMAIL,
      linkedin: process.env.VUE_APP_LINKEDIN,
      github: process.env.VUE_APP_GITHUB,
      location: process.env.VUE_APP_LOCATION,
      phone: process.env.VUE_APP_PHONE
    };
  },
  watch: {
    windowWidth() {
      if (this.windowWidth !== undefined) {
        this.showSmall = this.windowWidth < 576;
      }
    }
  },
  created() {
    if (!this.isHome) {
      window.print();
    }
  }
});
